
import { Vue, Component, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';

@Component
export default class ForbiddenPage extends Vue {
    @inject(AuthServiceS) private authService!: AuthService;

    logout() {
        this.authService.logout();
    }
}
